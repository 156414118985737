<template>
  <SignUpTemplate
    :right-image="userSignUpImage"
    :step="getStepNumber"
    class="UserView"
  >
    <div
      :class="[
        'UserView__content--aligned h-100',
        {
          'UserView__content--step_1 p-0 w-100': isOnFirstStep || isOnInvitationFirstStep,
          'UserView__content--step_2 py-0': isOnSecondStep,
          'UserView__content--step_3': isOnThirdStep || isOnInvitationThirdStep,
        },
      ]"
    >
      <div class="navigator pb-4 px-3 px-lg-0 w-100">
        <SignUpProgressBar
          :step-number="getStepNumber"
          :go-to-step="{ path: `./step${getStepNumber - 1}` }"
          :total-steps="isEmployee ? 2 : 3"
        />
      </div>
      <RouterView
        :auth="auth"
        class="content"
      />
    </div>
  </SignUpTemplate>
</template>
<script>

import { mapMutations, mapState } from 'vuex';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import ROUTES from '@Shared/router/routes-names';

import { getThemedAssetByCategoryAndKey } from '@/utils/assetsRepository';
import { ASSETS_CATEGORY, PICTURES_KEYNAMES } from '@/constants/assetsRepository';
import SignUpTemplate from '../Templates/SignUpTemplate';
import SignUpProgressBar from '../components/SignUpProgressBar';
import { USER_MODULE } from '../constants/modules';

export default {
  name: 'UserView',
  components: {
    SignUpProgressBar,
    SignUpTemplate,
  },
  props: {
    auth: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { notifyError } = useNotifications();
    return { notifyError };
  },
  computed: {
    ...mapState(USER_MODULE, {
      done: state => state.done,
      currentStep: state => state.currentStep,
      lastStepNumber: state => state.lastStep,
      isEmployee: state => state.b2b.isEmployee,
    }),
    isOnInvitationFirstStep() {
      return this.$route.name === ROUTES.signUpInvitationStep1;
    },
    isOnInvitationThirdStep() {
      return this.$route.name === ROUTES.signUpInvitationStep3;
    },
    isOnFirstStep() {
      return this.$route.name === ROUTES.signUpUserStep1;
    },
    isOnSecondStep() {
      return this.$route.name === ROUTES.signUpUserStep2;
    },
    isOnThirdStep() {
      return this.$route.name === ROUTES.signUpUserStep3;
    },
    getStepNumber() {
      const route = this.$route.name.match(/\d+/);
      const stepNumber = parseInt(route[0], 10) - (this.isEmployee && route[0] > 1 ? 1 : 0);

      return route ? stepNumber : 1;
    },
    userSignUpImage: () => getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.PICTURES, PICTURES_KEYNAMES.SIGNUP.b2c),
  },
  created() {
    this.notifyErrorCallback(this.notifyError);
  },
  beforeMount() {
    this.lastStep(this.getStepNumber);
  },
  methods: {
    ...mapMutations(USER_MODULE, ['resetUser', 'lastStep', 'notifyErrorCallback']),
    isActive(stepNumber) {
      return this.done[`step${stepNumber}`];
    },
  },
};
</script>
<style lang="scss" scoped>
.UserView {
  $already-have-account-height: 64px;
  $mobile-header-height: 40px;
  $responsive-size: 992px;
  $min-height-plus-spacing-step-1: 528px;
  $min-height-plus-spacing-step-2: 345px;

  &__content {
    &--aligned {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      height: calc(100% - $already-have-account-height);
      min-height: $min-height-plus-spacing-step-1;

      @media (max-width: $responsive-size) {
        justify-content: start;
        height: calc(100% - $mobile-header-height);
      }
    }

    &--step_2 {
      min-height: $min-height-plus-spacing-step-2;
    }

    &--step_3 {
      justify-content: flex-start;

      @media (max-width: $responsive-size) {
        padding-top: 0 !important;
      }
    }

    height: 100%;
    padding: 100px 0;
    vertical-align: middle;
  }

  &__navigator {
    &--hidden {
      display: none;
    }
  }
}
</style>
